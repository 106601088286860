import BasePage from './BasePage'
import $ from 'jquery'

export default class NewsletterPage extends BasePage {
    constructor(options) {
        super(options)

        this.$form = this.findChildren('#mc-embedded-subscribe-form')
        this.formAction = this.$form.attr('action')
        this.formAjaxUrl = this.getAjaxFormAction(this.formAction)
        this.$email = this.$form.find('#mce-EMAIL')
        this.$errorContainer = this.$form.find('#signup-error-response')
        this.$successContainer = this.$form.find('#signup-success-response')

        PellMell.header.setColorReverse(true)

        this.initMailchimpForm()
    }
    initMailchimpForm() {
        this.$form.on('submit.newsletter', this.handleFormSubmit.bind(this))
        this.$email.on('keypress.newsletter', () => {
            this.resetFeedbackMessages()
        })
    }
    handleFormSubmit(e) {
        e.preventDefault()

        this.resetFeedbackMessages()

        if (this.isEmailValid(this.$email.val())) {
            $.ajax({
                url: this.formAjaxUrl,
                data: {
                    [this.$email.attr('name')]: this.$email.val()
                },
                dataType: 'json',
                contentType: 'application/json; charset=utf-8',
                success: this.handleFormSubmitSuccess.bind(this)
            })
        } else {
            this.displayError('Please enter a valid email address.')
        }

        return false
    }
    handleFormSubmitSuccess(response, type, request) {
        switch (response.result) {
            case 'error':
                this.displayError(response.msg)
                break
            case 'success':
                this.resetForm()
                this.displaySuccess(response.msg)
                break
            default:
                this.displayError(response.msg)
                break
        }
    }
    resetForm() {
        this.$email.val('')
    }
    resetFeedbackMessages() {
        this.$errorContainer.removeClass('is-active')
        this.$successContainer.removeClass('is-active')
    }
    displayError(msg) {
        this.$errorContainer.html(msg).addClass('is-active')
    }
    displaySuccess(msg) {
        this.$successContainer.html(msg).addClass('is-active')
    }
    isEmailValid(email) {
        // Regexp from http://www.analyste-programmeur.com/javascript/les-expressions-regulieres/verifier-une-adresse-email-en-javascript
        const rg = /^[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,5}$/i
        return email != '' && rg.test(email)
    }
    getAjaxFormAction(url) {
        url = url.replace('/post?u=', '/post-json?u=')
        url += '&c=?'
        return url
    }
    removeEvents() {
        BasePage.prototype.removeEvents.apply(this, arguments)
        this.$form.off('submit.newsletter')
        this.$email.off('keypress.newsletter')
    }
}
