import $ from 'jquery'
import TweenLite from 'tweenlite'
import TimelineLite from 'timelinelite'

export default class PellMellNav {
    constructor() {
        this.previousHeaderColor = false
        this.isOpen = false
        this.animMenuTimeline = null
        this.$menu = $('#main-menu')
        this.$menuToggle = $('#main-menu-toggle')
        this.$scrollerContainer = this.$menu.find('.navigation-scroller')
        this.$artistsBgs = this.$menu.find('.navigation-backgrounds li')
        this.$links = this.$menu.find('.navigation-link')

        this.$artistLinks = this.$links.filter('[data-pm-nodetype="artist"]')
        this.$artistsParentLink = this.$links.filter('[data-pm-nodename="artist"]')
        this.$otherLinks = this.$links.filter('[data-pm-nodetype!="artist"][data-pm-nodename!="artist"]')

        this.$pagesList = this.$menu.find('#navigation-pages-list')
        this.$artistList = this.$menu.find('#navigation-artists-list')
        this.artistsScrollDiff = 0

        this.init()
    }
    init() {
        this.addEvents()
    }
    addEvents() {
        if (this.$links && this.$links.length && PellMell.ajaxEnabled) {
            this.$links.filter('a').on('click', PellMell.history.handleLinkClick.bind(PellMell.history))
        }
        if (this.$menuToggle) {
            this.$menuToggle.on('click', this.toggleMenu.bind(this))
        }
        this.$links.on('mouseenter', this.hanldeLinkMouseenter.bind(this))
        this.$links.on('mouseleave', this.hanldeLinkMouseleave.bind(this))
    }
    resetBackground() {
        const $activeArtist = this.$artistLinks.filter('.is-active')

        if ($activeArtist.length > 0) {
            this.changeBackgroundByNodeName($activeArtist.eq(0).attr('data-pm-nodename'))
        } else {
            const rdmBgIndex = Math.round(Math.random() * (this.$artistsBgs.length - 1))
            this.changeBackgroundByIndex(rdmBgIndex)
        }
    }
    changeBackgroundByNodeName(linkNodeName) {
        this.$artistsBgs
            .removeClass('is-active')
            .filter(`[data-pm-nodename="${linkNodeName}"]`)
                .addClass('is-active')
    }
    changeBackgroundByIndex(index) {
        this.$artistsBgs
            .removeClass('is-active')
            .eq(index)
                .addClass('is-active')
    }
    toggleMenu(e) {
        e.preventDefault()

        // Only open on mouseenter (close it on click)
        if (e.type == 'mouseenter' && this.isOpen) {
            return false
        }

        if (this.isOpen) {
            this.closeMenu()
        } else {
            this.openMenu()
        }
    }
    openMenu() {
        this.$menuToggle.addClass('is-open')
        this.$scrollerContainer.scrollTop(0)
        this.isOpen = true
        this.resetBackground()
        this.previousHeaderColor = PellMell.header.getColorReverse()
        PellMell.header.setColorReverse(true)

        if (this.animMenuTimeline) {
            this.animMenuTimeline.kill()
            this.animMenuTimeline = null
        }

        const tl = new TimelineLite({
            onComplete: this.handleOpenMenuComplete,
            onCompleteScope: this
        })
        const level0LinksArr = this.$menu.find('.navigation-item--level0').toArray()
        const level1LinksArr = this.$menu.find('.navigation-item--level1').toArray()

        tl.to(this.$menu[0], 0, {opacity: 0})
        tl.to(level0LinksArr, 0, {opacity: 0, y: '100%'})
        tl.to(level1LinksArr, 0, {opacity: 0, x: '10%'})
        this.$menu.addClass('is-open')
        $(window).trigger('resize')
        tl.to(this.$menu[0], 0.5, {opacity: 1})
        tl.staggerTo(level0LinksArr, 0.7, {opacity: 1, y: '0%'}, 0.05)
        tl.staggerTo(level1LinksArr, 0.7, {opacity: 1, x: '0%'}, 0.05, '-=0.4')

        this.animMenuTimeline = tl
    }
    handleOpenMenuComplete() {
        // console.log('handleOpenMenuComplete', this)
        this.calculArtistScrollDiff()
        this.addMousemoveListener()
    }
    closeMenu() {
        if (!this.isOpen) {
            return // prevent extra work - debug resetHeaderColor
        }

        this.removeMousemoveListener()
        this.$menuToggle.removeClass('is-open')
        PellMell.header.setColorReverse(this.previousHeaderColor)

        if (this.animMenuTimeline) {
            this.animMenuTimeline.kill()
            this.animMenuTimeline = null
        }

        const tl = new TimelineLite({
            onComplete: this.handleCloseMenuComplete,
            onCompleteScope: this
        })
        const allLinksArr = this.$menu.find('.navigation-item').toArray()

        tl.to(allLinksArr, 0.3, {opacity: 0})
        tl.to(this.$menu[0], 0.5, {opacity: 0})

        this.animMenuTimeline = tl
    }
    handleCloseMenuComplete() {
        // console.log('handleCloseMenuComplete', this)
        this.$menu.removeClass('is-open')
        this.isOpen = false
    }
    updateUI(stateObj) {
        // Update current active menu item
        this.$links
                .removeClass('is-active')
                .filter(`[data-pm-nodetype="${stateObj.nodeType}"][data-pm-nodename="${stateObj.nodeName}"]`)
                    .addClass('is-active')
                    .trigger('mouseenter') // force menu highlighted/faded links logic

        // default state if none of the links are active
        const $activeLink = this.$links.filter('.is-active')
        if (!$activeLink.length) {
            this.$artistsParentLink
                    .addClass('is-active')
                    .trigger('mouseenter')
        }
    }
    hanldeLinkMouseleave(e) {
        // empty
    }
    hanldeLinkMouseenter(e) {
        const $currentlink = $(e.currentTarget)
        const linkNodeType = $currentlink.attr('data-pm-nodetype')
        const linkNodeName = $currentlink.attr('data-pm-nodename')

       // Change menu bg
        if (linkNodeType == 'artist') { // mouseover artist link
            this.changeBackgroundByNodeName(linkNodeName)
        }

        this.$links.removeClass('is-faded is-highlighted')

        // Update items styles
        if (linkNodeType == 'artist' || linkNodeName == 'artist') {
            this.$artistLinks
                .addClass('is-highlighted')
            this.$artistsParentLink
                .addClass('is-highlighted')
            this.$otherLinks
                .addClass('is-faded')
        } else {
            this.$artistLinks
                .addClass('is-faded')
            this.$artistsParentLink
                .addClass('is-faded')
            this.$otherLinks
                .addClass('is-faded')
        }

        $currentlink.addClass('is-highlighted')
        $currentlink.toggleClass('anim-reverse')
    }
    addMousemoveListener() {
        this.removeMousemoveListener() // in case it exist already
        if (!PellMell.isTouchDevice) {
            $(document).on('mousemove.menu', this.handleMousemove.bind(this))
        }
    }
    removeMousemoveListener() {
        $(document).off('mousemove.menu')
    }
    handleMousemove(e) {
        const mouseY = e.clientY || e.offsetY || false

        if (this.isOpen && mouseY && this.artistsScrollDiff) {
            const topPerc = mouseY / PellMell.viewport.height
            const yTo = Math.round(topPerc * this.artistsScrollDiff * 1000) /1000
            TweenLite.to(this.$artistList[0], 0.8, {y: yTo + 'px' })
        }
    }
    handleResize() {
        if (this.isOpen) {
            this.calculArtistScrollDiff()
        }
    }
    calculArtistScrollDiff() {
        const arstistsListRect = this.$artistList[0].getBoundingClientRect()
        const pagesListRect = this.$pagesList[0].getBoundingClientRect()
        let scrollDiff = pagesListRect.height - arstistsListRect.height
        this.artistsScrollDiff = scrollDiff < 0 ? scrollDiff : 0
        if (scrollDiff >= 0) {
            TweenLite.to(this.$artistList[0], 0.8, {y: '0px' })
        }
    }
}
