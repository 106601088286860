import _merge from 'lodash.merge'
import _debounce from 'lodash.debounce'
import TweenLite from 'tweenlite'

const alignTypes = ['center', 'topleft', 'top', 'topright', 'right', 'bottomright', 'bottom', 'bottomleft', 'left']

const defaultOptions = {
    align: alignTypes[0]
}

export default class ImageCover {
    constructor(selector, options = {}) {
        let settings = _merge({}, defaultOptions, options)

        // Safe align type
        if (alignTypes.indexOf(settings.align) == -1) {
            settings.align = alignTypes[0]
            console.error(`[ImageCover] Align type "${options.align}" not valid, default with "${settings.align}"`)
        }

        this.selector = selector
        this.settings = settings
        this.boundHandleResize = _debounce(this.handleResize.bind(this), 50)

        this.elements = []

        this.init()

        return this
    }
    init() {
        this.addEvents()
        this.loadImages()
    }
    reset(){
        this.loadImages()
    }
    loadImages() {
        const images = document.querySelectorAll(this.selector)
        let elements = []

        for(let i = 0; i < images.length; i++) {
            this.addImage(images[i])
        }
    }
    addImage(imgEl) {
        if (imgEl.naturalWidth > 0 && imgEl.naturalHeight > 0) {
            this.handleImageLoaded.apply(this, [imgEl])
        } else {
            TweenLite.to(imgEl, 0, {opacity: 0}) // hide while loading
        }
        imgEl.addEventListener('load', this.handleImageLoaded.bind(this, imgEl))
    }
    handleImageLoaded(img) {
        const el = {
            img: img,
            width: img.naturalWidth,
            height: img.naturalHeight,
            ratio: img.naturalWidth/img.naturalHeight,
            parent: img.parentNode,
            canFit: img.classList.contains('resize-fit')
        }
        this.elements.push(el)
        this.resizeAndReplaceImg(el) // force first resize
        TweenLite.to(img, .5, {opacity: 1})
    }
    handleResize() {
        for(let i = 0; i < this.elements.length; i++) {
            this.resizeAndReplaceImg(this.elements[i])
        }
    }
    resizeAndReplaceImg(el) {
        if (!el.img || !el.parent) {
            return false // stop here ! - debug if img was removed from dom
        }
        const cW = el.parent.offsetWidth
        const cH = el.parent.offsetHeight
        const cR = cW/cH
        let props = {
            position: 'absolute',
            width: '',
            height: '',
            top: '',
            right: '',
            bottom: '',
            left: ''
        }
        let resizeType = el.canFit && (cR < 1 && el.ratio > 1 || cR > 1 && el.ratio < 1) ? 'fit' : 'fill'

        // Resize
        switch(resizeType) {
            case 'fill':
                if (el.ratio < cR) {
                    props.width = cW
                    props.height = props.width / el.ratio
                }
                else {
                    props.height = cH
                    props.width = props.height * el.ratio
                }
                break
            case 'fit':
                if (el.ratio < cR) {
                    props.height = cH
                    props.width = props.height * el.ratio
                }
                else {
                    props.width = cW
                    props.height = props.width / el.ratio
                }
                break
            default:
                break
        }

        // Replace
        switch(this.settings.align) {
            case 'center':
                props.top = (cH - props.height) / 2
                props.left = (cW - props.width) / 2
                break
            case 'topleft':
                props.top = 0
                props.left = 0
                break
            case 'top':
                props.top = 0
                props.left = (cW - props.width) / 2
                break
            case 'topright':
                props.top = 0
                props.right = 0
                break
            case 'right':
                props.top = (cH - props.height) / 2
                props.right = 0
                break
            case 'bottomright':
                props.bottom = 0
                props.right = 0
                break
            case 'bottom':
                props.bottom = 0
                props.left = (cW - props.width) / 2
                break
            case 'bottomleft':
                props.bottom = 0
                props.left = 0
                break
            case 'left':
                props.top = (cH - props.height) / 2
                props.left = 0
                break
            default:
                break
        }

        // set css
        for(const key in props) {
            let propValue = props[key]
            if (typeof propValue == 'number') {
                propValue += 'px'
            }
            el.img.style[key] = propValue
        }
    }
    addEvents() {
        window.addEventListener('resize', this.boundHandleResize)
        window.addEventListener('orientationchange', this.boundHandleResize)
    }
    removeEvents() {
        window.removeEventListener('resize', this.boundHandleResize)
        window.removeEventListener('orientationchange', this.boundHandleResize)
    }
    destroy() {
        this.removeEvents()
    }
}
