export default class PellMellLoader {
    constructor() {
        this.isVisible = false
        this.$el = document.getElementById('main-loader')
    }
    show() {
        if (!this.isVisible) {
            this.$el.style.display = 'block'
            this.isVisible = true
        }
    }
    hide() {
        if (this.isVisible) {
            this.$el.style.display = 'none'
            this.isVisible = false
        }
    }
}
