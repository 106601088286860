import FeedPage from './FeedPage'
import $ from 'jquery'
import TweenLite from 'tweenlite'

export default class ArtistPage extends FeedPage {
    constructor(options) {
        super(options)

        this.$header = this.findChildren('.artist-header')
        this.$feedContainer = this.findChildren('.artist-feed')
        this.$feed = this.findChildren('.artist-feed .feed')
        this.$scrollDownBtn = this.findChildren('.artist-scrollDown')
        this.isAnimating = false
        this.$window = $(window)
        this.$document = $(document)
        this.lastScrollPos = 0
        this.showFeedDirectly = window.location.hash == '#artist-feed'
        const isHeaderReverseColor = this.$el.attr('data-pm-headerReverseColor') == 'true' ? true : false

        PellMell.header.setColorReverse(isHeaderReverseColor)

        this.init()
    }
    init() {
        this.$scrollDownBtn.on(`click.${this.id}`, this.handleScrollDownClick.bind(this))
        if (!PellMell.isTouchDevice) {
            this.$window.on(`scroll.${this.id}`, this.handleScroll.bind(this))
        }
    }
    transitionIn() {
        if (!this.showFeedDirectly) {
            FeedPage.prototype.transitionIn.apply(this, arguments)
        }
        else {
            this.transitionInEnd()
        }
    }
    transitionInEnd() {
        FeedPage.prototype.transitionInEnd.apply(this, arguments)

        if (this.showFeedDirectly) {
            this.scrollToFeed(true)
            TweenLite.to(this.$el[0], 0.3, {opacity: 1})
        }
    }
    handleScroll(e) {
        const wScrollTop = this.$window.scrollTop()

        // Debug prevent scroll inside menu
        if (PellMell.nav.isOpen) {
            return true // stop here !
        }

        if (this.isAnimating) {
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()
            return false // stop here !
        }

        if (wScrollTop < PellMell.viewport.height && !this.isAnimating) {
            e.preventDefault()
            e.stopPropagation()

            if (wScrollTop > this.lastScrollPos) {
                this.scrollToFeed()
            } else if (wScrollTop < this.lastScrollPos){
                this.scrollTo()
            }
        }

        this.lastScrollPos = wScrollTop
    }
    handleScrollDownClick(e) {
        e.preventDefault()
        e.stopPropagation()
        this.scrollToFeed()
    }
    scrollToFeed(preventAnim = false) {
        this.scrollTo(this.$feedContainer.offset().top, preventAnim)
    }
    scrollTo(y = 0, preventAnim = false) {
        this.isAnimating = true
        TweenLite.to(window, preventAnim ? 0 : 1, {
            scrollTo: {
                y: y,
                autoKill: false
            },
            onComplete: () => {
                this.lastScrollPos = this.$window.scrollTop()
                this.isAnimating = false
            }
        })
    }
    beforeTransitionOut() {
        FeedPage.prototype.beforeTransitionOut.apply(this, arguments)
        this.$window.off(`scroll.${this.id}`)
    }
}
