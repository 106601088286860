import BasePage from './BasePage'
import Swiper from 'swiper'
import $ from 'jquery'
import ProgressBar from 'progressbar.js'

export default class HomePage extends BasePage {
    constructor(options) {
        super(options)

        this.$carousel = this.findChildren('.swiper-container')
        this.carouselSwiper = null
        this.progressBars = null
        this.autoPlayTime = 4000

        this.initLinks()
        this.createProgressBars()
        this.initCarousel()
    }
    initLinks() {
        if (PellMell.ajaxEnabled) {
            // delegate event handling because Swiper duplicates slides for 'loop' behaviour
            this.$el.on('click.homepage', '.swiper-artistName', PellMell.history.handleLinkClick.bind(PellMell.history))
        }
    }
    createProgressBars() {
        let progressBars = []
        const $circles = $('.swiper-progressbars-circle')

        if ($circles && $circles.length) {
            $circles.each((index, circleEl) => {
                const progressBar = new ProgressBar.Circle(circleEl, {
                    strokeWidth: 6,
                    color: 'currentColor',
                    duration: this.autoPlayTime
                })
                progressBar.set(0)
                progressBars.push(progressBar)
            })
        }

        this.progressBars = progressBars
    }
    initCarousel() {
        if (this.$carousel) {
            this.carouselSwiper = new Swiper(this.$carousel[0], {
                loop: true,
                pagination: '.swiper-pagination',
                autoplay: this.autoPlayTime,
                autoplayDisableOnInteraction: false,
                paginationType: 'bullets',
                simulateTouch: false,
                paginationBulletRender: (index, className) => {
                    return '<a href="#'+index+'" class="'+className+'">' +
                            '<span class="swiper-pagination-bullet-progress"></span>' +
                            '<span class="swiper-pagination-bullet-dot"></span>' +
                        '</a>'
                },
                effect: 'slide',
                paginationClickable: true,
                keyboardControl: true,
                onSlideChangeStart: (swiper) => {
                    const realActiveIndex = this.getRealActiveSlideIndex(swiper)
                    const realPrevIndex = this.getRealPrevSlideIndex(swiper)
                    const prevProgressBar = this.progressBars[realPrevIndex]
                    const activeProgressBar = this.progressBars[realActiveIndex]
                    if (prevProgressBar && prevProgressBar != activeProgressBar) {
                        prevProgressBar.animate(0, {duration: 300})
                    }
                    activeProgressBar.animate(1)
                    this.handleCarouseSlideChanged(swiper)
                },
                // onAutoplay: (swiper) => {}, // Same as onSlideChangeStart but caused by autoplay
                onAutoplayStart: (swiper) => {
                    const realActiveIndex = this.getRealActiveSlideIndex(swiper)
                    const activeProgressBar = this.progressBars[realActiveIndex]
                    activeProgressBar.animate(1)
                },
                onAutoplayStop: (swiper) => {
                    const realActiveIndex = this.getRealActiveSlideIndex(swiper)
                    const activeProgressBar = this.progressBars[realActiveIndex]
                    activeProgressBar.animate(0, {duration: 300})
                },
                onTouchStart: (swiper) => {
                    const realActiveIndex = this.getRealActiveSlideIndex(swiper)
                    const activeProgressBar = this.progressBars[realActiveIndex]
                    activeProgressBar.stop()
                },
                onTouchEnd: (swiper) => {
                    const realActiveIndex = this.getRealActiveSlideIndex(swiper)
                    const activeProgressBar = this.progressBars[realActiveIndex]
                    activeProgressBar.animate(1, {duration: this.autoPlayTime})
                },
            })
        }
    }
    getRealActiveSlideIndex(swiper) {
        return this.getRealSlideIndex(swiper.slides[swiper.activeIndex])
    }
    getRealPrevSlideIndex(swiper) {
        return this.getRealSlideIndex(swiper.slides[swiper.previousIndex])
    }
    getRealSlideIndex(slide) {
        return parseInt($(slide).attr('data-swiper-slide-index'))
    }
    handleCarouseSlideChanged(swiper) {
        const $currentSlide = $(swiper.slides[swiper.activeIndex])
        let slideColorReversed = $currentSlide.attr('data-pm-headerreversecolor')
        slideColorReversed = slideColorReversed && slideColorReversed == "true" ? true : false

        this.updatePageColors(slideColorReversed)
        if (!PellMell.nav.isOpen) {
            PellMell.header.setColorReverse(slideColorReversed)
        }
    }
    beforeTransitionIn() {
        BasePage.prototype.beforeTransitionIn.apply(this, arguments)

        if (this.carouselSwiper) {
           this.carouselSwiper.onResize() // force size update
        }
    }
    transitionIn() {
        // force resize in case previous page had a scrollbar
        if (this.carouselSwiper) {
           this.carouselSwiper.onResize()
        }
        if (PellMell.imageCoverHelper) {
           PellMell.imageCoverHelper.handleResize()
        }

        BasePage.prototype.transitionIn.apply(this, arguments)
    }
    beforeTransitionOut() {
        BasePage.prototype.beforeTransitionOut.apply(this, arguments)
        if (this.carouselSwiper) {
           this.carouselSwiper.stopAutoplay()
        }
    }
    beforeDestroy() {
        BasePage.prototype.beforeDestroy.apply(this, arguments)
        // this.pageLog('homepage.beforeDestroy')

        if (this.carouselSwiper) {
            this.carouselSwiper.detachEvents()
            this.carouselSwiper.destroy()
        }
        if (this.progressBars && this.progressBars.length) {
            for(var progressBar in this.progressBar) {
                progressBar.destroy()
            }
        }
    }
}
