import BasePage from './BasePage'
import $ from 'jquery'
import Swiper from 'swiper'
import noUiSlider from 'nouislider'
import ProjectZoomOverlay from './ProjectZoomOverlay.js'
require('../vendors/froogaloop.js')

export default class ProjectPage extends BasePage {
    constructor(options) {
        super(options)

        this.$carousel = null
        this.carouselSwiper = null
        this.$currentSlide = null
        this.$currentSlideImg = null
        this.currentSlideImgRect = null
        this.$footerToggle = null
        this.$footer = null
        this.$footerBg = null
        this.isFooterOpen = false
        this.$projectsNavBtns = null
        this.$prevProjectBtn = null
        this.$nextProjectBtn = null
        this.$prevNavBtns = null
        this.$nextNavBtns = null
        this.$zoomBtn = null
        this.$closeBtn = null
        this.$videoPlayers = null
        this.$videoPlayBtns = null
        this.isCurrentSlideVideo = false
        this.$zoomSlider = null
        this.zoomSliderUi = null
        this.userHasZoomed = false
        this.$zoomOverlayContainer = null
        this.zoomOverlayClass = null

        this.reset()
    }
    reset() {
        // video players
        this.$videoPlayers = this.findChildren('iframe.vimeo-player')
        this.$videoPlayBtns = this.findChildren('.vimeo-playBtn')
        this.initVimeoPlayers()

        // close
        if (this.$closeBtn) {
            this.$closeBtn.off(`click.${this.id}`)
            this.$closeBtn = null
        }
        this.$closeBtn = this.findChildren('.project-header-close')
        this.$closeBtn.on(`click.${this.id}`, PellMell.history.handleLinkClick.bind(PellMell.history))

        // prev/next project/slide buttons
        this.$prevNavBtns = this.findChildren('.project-nav-btn.project-nav-prev')
        this.$nextNavBtns = this.findChildren('.project-nav-btn.project-nav-next')

        // projects nav
        if (this.$projectsNavBtns) {
            this.$projectsNavBtns.off(`click.${this.id}`)
            this.$projectsNavBtns = null
        }
        this.$projectsNavBtns = this.findChildren('.project-switcher .project-nav-btn')
        this.$projectsNavBtns.on(`click.${this.id}`, PellMell.history.handleLinkClick.bind(PellMell.history))
        this.$prevProjectBtn = this.$projectsNavBtns.filter('.project-nav-prev')
        this.$nextProjectBtn = this.$projectsNavBtns.filter('.project-nav-next')

        // footer
        if (this.$footer) {
            this.$footer = null
        }
        this.$footer = this.findChildren('.project-footer')
        this.$footerBg = this.findChildren('.project-footer-bg')
        this.closeFooter()

        // footer artist link
        if (this.$footerArtistLink) {
            this.$footerArtistLink.off(`click.${this.id}`)
            this.$footerArtistLink = null
        }
        this.$footerArtistLink = this.findChildren('.project-footer-artistlink')
        this.$footerArtistLink.on(`click.${this.id}`, PellMell.history.handleLinkClick.bind(PellMell.history))

        // footer toggle
        if(this.$footerToggle) {
            this.$footerToggle
                    .off(`click.${this.id}`)
                    .off(`mouseenter.${this.id}`)
            this.$footerToggle = null
        }
        this.$footerToggle = this.findChildren('.project-footer-toggle')
        const openEvent = PellMell.isTouchDevice ? 'click' : 'mouseenter'
        this.$footerToggle.on(`${openEvent}.${this.id}`, this.handleFooterToggleClick.bind(this))

        // zoom slider
        if (this.$zoomSlider) {
            this.$zoomSlider = null
        }
        this.$zoomSlider = this.findChildren('.project-zoom')
        this.initZoomSlider()

        // zoom overlay
        if (this.$zoomOverlayContainer) {
            this.$zoomOverlayContainer = null
        }
        this.$zoomOverlayContainer = this.findChildren('.project-zoom-overlay')
        this.zoomOverlayClass = new ProjectZoomOverlay(this.$zoomOverlayContainer)

        this.$zoomBtn = this.findChildren('.project-nav-btn.project-nav-zoom')
        if (!PellMell.isTouchDevice) {
            this.$zoomBtn.on(`click.${this.id}`, this.handleZoomClick.bind(this))
        }

        // carousel
        if (this.$carousel) {
            this.$carousel = null
        }
        if (this.carouselSwiper) {
            this.carouselSwiper.destroy()
        }
        this.$carousel = this.findChildren('.swiper-container')
        this.initCarousel()
    }
    addEvents() {
        BasePage.prototype.addEvents.apply(this, arguments)
        $(document).on(`keydown.${this.id}`, this.handleKeyboardKeyPress.bind(this))
        if (!PellMell.isTouchDevice) {
            $(document).on(`mousemove.${this.id}`, this.handleMouseMove.bind(this))
        }
    }
    removeEvents() {
        BasePage.prototype.removeEvents.apply(this, arguments)
        $(document).off(`keydown.${this.id}`)
        $(document).off(`mousemove.${this.id}`)
    }
    handleZoomClick(e) {
        e.preventDefault()
        this.zoomOverlayClass.show(this.currentZoomImgSrc)
    }
    initVimeoPlayers() {
        if (this.$videoPlayers && this.$videoPlayers.length) {
            this.$videoPlayers.each((index, player) => {
                const vimeoPlayer = $f(player)
                vimeoPlayer.addEvent('ready', () => {
                    vimeoPlayer.addEvent('pause', () => {
                        this.onPlayerStop(index)
                    })
                    vimeoPlayer.addEvent('finish', () => {
                        this.onPlayerStop(index)
                    })
                    vimeoPlayer.addEvent('play', () => {
                        this.onPlayerPlay(index)
                    })
                })
            })
        }
        if (this.$videoPlayBtns && this.$videoPlayBtns.length) {
            this.$videoPlayBtns.each((index, button) => {
                $(button).on('click', (e) => {
                    e.preventDefault()
                    const vimeoPlayer = $f(this.$videoPlayers[index])
                    vimeoPlayer.api('play')
                })
            })
        }
    }
    onPlayerStop(playerIndex) {
        this.$videoPlayBtns.eq(playerIndex).show()
    }
    onPlayerPlay(playerIndex) {
        this.$videoPlayBtns.eq(playerIndex).hide()
    }
    initZoomSlider() {
        this.zoomSliderUi = noUiSlider.create(this.$zoomSlider[0], {
            start: 1,
            connect: 'lower',
            range: {
              'min': 0.5,
              'max': 1.5
            }
        })
        this.zoomSliderUi.on('update', this.handleZoomUpdate.bind(this))
    }
    resetZoomSlider() {
        if (!this.zoomSliderUi) {
            return false // stop here !
        }

        let scaleDiff = 0.5

        if (this.$currentSlideImg) {
            const currentImg = this.$currentSlideImg[0]
            if (currentImg.naturalWidth && currentImg.naturalHeight) {
                scaleDiff = this.getZoomScaleDiff(this.$currentSlideImg)
            }
            else {
                this.$currentSlideImg.off(`load.${this.id}`)
                this.$currentSlideImg.one(`load.${this.id}`, () => {
                    // make sur cover helper resized it first
                    setTimeout(this.resetZoomSlider.bind(this), 50)
                })
            }
        }
        // Allow only unzoom on touch devices
        this.zoomSliderUi.updateOptions({
            range: {
                min: 1 - scaleDiff,
                max: PellMell.isTouchDevice ? 1 : 1.5
            }
        })
        this.zoomSliderUi.set([1])
        this.userHasZoomed = false
    }
    getZoomScaleDiff($img) {
        const imgW = $img.width()
        const imgH = $img.height()
        const imgR = imgW/imgH
        const {width: vW, height: vH} = PellMell.viewport
        const vR = vW/vH
        let scaleDiff = 0.5

        if (imgR < vR) {
            scaleDiff = 1 - imgR / vR
        } else {
            scaleDiff = 1 - vR / imgR
        }
        return Math.round(scaleDiff * 1000) / 1000
    }
    handleZoomUpdate(values, handle) {
        const zoomLevel = values[handle]
        if (this.$currentSlideImg) {
            TweenLite.to(this.$currentSlideImg, 0.8, {
                scale: zoomLevel,
                onComplete: () => {
                    this.currentSlideImgRect = this.$currentSlideImg[0].getBoundingClientRect()
                    $(document).trigger('mousemove') // update image position
                }
            })
            if (zoomLevel != 1) {
                this.userHasZoomed = true
            }
        }
    }
    toggleZoomSlider(show) {
        this.$zoomSlider[show?'removeClass':'addClass']('is-hidden')
    }
    initCarousel() {
        const $initialSlide = this.$carousel.find('[data-pm-isInitialSlide="true"]')
        const initialSlideIndex = parseInt($initialSlide.attr('data-pm-slideIndex')) || 0
        this.carouselSwiper = new Swiper(this.$carousel[0], {
            initialSlide: initialSlideIndex,
            simulateTouch: false,
            effect: 'slide',
            keyboardControl: true,
            prevButton: '.swiper-button-prev',
            nextButton: '.swiper-button-next',
            onInit: (swiper) => {
                this.handleCarouseSlideChanged(swiper)
            },
            onSlideChangeStart: (swiper) => {
                this.handleCarouseSlideChanged(swiper)
                this.pauseAllVideos()
            }
        })
    }
    handleCarouseSlideChanged(swiper) {
        this.$currentSlide = $(swiper.slides[swiper.activeIndex])
        this.$currentSlideImg = this.$currentSlide.find('img')
        this.$currentSlideImg = this.$currentSlideImg.length ?
            this.$currentSlideImg.eq(0) :
            false
        const $slideImgContainer = this.$currentSlide.find('.swiper-imgContainer')
        this.currentZoomImgSrc = $slideImgContainer.length ?
            $slideImgContainer.eq(0).attr('data-pm-overlayImgSrc') :
            false
        const slideBgColor = this.$currentSlide.attr('data-pm-bgcolor')
        const slideVideoIframe = this.$currentSlide.find('.swiper-videoIframe')
        let slideColorReversed = this.$currentSlide.attr('data-pm-headerreversecolor')
        slideColorReversed = slideColorReversed && slideColorReversed == "true" ? true : false

        this.isCurrentSlideVideo = slideVideoIframe && slideVideoIframe.length
        $(document).trigger('mousemove') // update hide/show navigation

        if (this.$prevProjectBtn) {
            this.$prevProjectBtn[swiper.isBeginning?'removeClass':'addClass']('is-disabled')
        }
        if (this.$nextProjectBtn) {
            this.$nextProjectBtn[swiper.isEnd?'removeClass':'addClass']('is-disabled')
        }
        this.$zoomBtn[this.currentZoomImgSrc?'removeClass':'addClass']('is-disabled')

        this.updateFooterBgColor(slideBgColor)
        this.updateZoomColor(slideBgColor)
        this.resetZoomSlider()
        this.toggleZoomSlider(this.isCurrentSlideVideo ? false : true)
        this.updatePageColors(slideColorReversed)
        PellMell.header.setColorReverse(slideColorReversed)
    }
    handleFooterToggleClick(e) {
        e.preventDefault()
        if (this.isFooterOpen) {
            this.closeFooter()
        } else {
            this.openFooter()
        }
    }
    handleKeyboardKeyPress(e) {
        switch(e.keyCode) {
            case 27: // esc = close carousel
                if (this.$closeBtn) {
                    this.$closeBtn.trigger('click')
                }
                break
            case 39: // -> = next project
                if (this.$nextProjectBtn && this.carouselSwiper.isEnd) {
                    this.$nextProjectBtn.trigger('click')
                }
                break
            case 37: // <- = prev project
                if (this.$prevProjectBtn && this.carouselSwiper.isBeginning) {
                    this.$prevProjectBtn.trigger('click')
                }
                break
            default:
                break
        }
    }
    handleMouseMove(e) {
        e.pageX = e.pageX || PellMell.lastMousePos.pageX
        e.pageY = e.pageY || PellMell.lastMousePos.pageY

        if (PellMell.nav.isOpen || !e.pageX || !e.pageY) {
            return
        }

        this.projectNavFollowCursor(e.pageX, e.pageY)
        // this.projectImgReplace(e.pageX, e.pageY)

        PellMell.lastMousePos = {
            pageX: e.pageX,
            pageY: e.pageY,
        }
    }
    /*projectImgReplace(pageX, pageY) {
        if (!this.userHasZoomed || !this.$currentSlideImg || !this.currentSlideImgRect) {
            return false // stop here
        }

        const {width: vW, height: vH} = PellMell.viewport
        const pageXPerc = pageX / vW * 100
        const pageYPerc = pageY / vH * 100
        const diffWidth = (vW - this.currentSlideImgRect.width)
        const diffHeight = (vH - this.currentSlideImgRect.height)
        const xTo = diffWidth < 0 ?
            (diffWidth / 100 * pageXPerc) - (diffWidth/2) :
            0
        const yTo = diffHeight < 0 ?
            (diffHeight / 100 * pageYPerc) - (diffHeight/2) :
            0

        TweenLite.to(this.$currentSlideImg[0], 0.8, {x: xTo + 'px', y: yTo + 'px'})
    }*/
    projectNavFollowCursor(pageX, pageY) {
        const {viewport} = PellMell
        const activeZoneWidth = viewport.width * 0.15
        const activeZoneHeight = 120
        const isInActiveHeightZone = pageY > activeZoneHeight && pageY < (viewport.height - activeZoneHeight)
        const followProps = {
            top: pageY,
            left: pageX,
            right: 'auto',
            bottom: 'auto',
            x: '-50%',
            y: '-50%',
            cursor: 'none',
            'pointer-events': '',
            opacity: 1
        }
        const hiddenProps = {
            opacity: 0,
            'pointer-events': 'none'
        }
        const resetProps = {
            top: '',
            left: '',
            right: '',
            bottom: '',
            transform: '',
            cursor: '',
            'pointer-events': '',
            opacity: ''
        }

        if (this.$prevNavBtns && this.$prevNavBtns.length) {
            if (!this.isCurrentSlideVideo) {
                const prevProps = pageX < activeZoneWidth && isInActiveHeightZone ? followProps : hiddenProps
                TweenLite.to(this.$prevNavBtns.toArray(), 0, prevProps)
            } else {
                this.$prevNavBtns.css(resetProps)
            }
        }
        if (this.$nextNavBtns && this.$nextNavBtns.length) {
            if (!this.isCurrentSlideVideo) {
                const nextProps = pageX > (viewport.width - activeZoneWidth) && isInActiveHeightZone ? followProps : hiddenProps
                TweenLite.to(this.$nextNavBtns.toArray(), 0, nextProps)
            } else {
                this.$nextNavBtns.css(resetProps)
            }
        }
        if (this.$zoomBtn && this.$zoomBtn.length) {
            if (!this.isCurrentSlideVideo) {
                const nextProps =
                    pageX > activeZoneWidth &&
                    pageX < (viewport.width - activeZoneWidth) &&
                    isInActiveHeightZone ?
                        followProps :
                        hiddenProps
                TweenLite.to(this.$zoomBtn.toArray(), 0, nextProps)
            } else {
                this.$zoomBtn.css(resetProps)
            }
        }
    }
    updateFooterBgColor(color) {
        this.$footerBg.css('background-color', color)
    }
    updateZoomColor(color) {
        this.$zoomSlider.css('background-color', color)
    }
    openFooter() {
        this.$el.addClass('is-footer-open')
        this.$footer.addClass('is-open')
        this.$footer.on('mouseleave.projectpage', this.closeFooter.bind(this))
        this.isFooterOpen = true
    }
    closeFooter() {
        this.$footer.off('mouseleave.projectpage')
        this.$footer.removeClass('is-open')
        this.$el.removeClass('is-footer-open')
        this.isFooterOpen = false
    }
    pauseAllVideos() {
        if (this.$videoPlayers && this.$videoPlayers.length) {
            const message = JSON.stringify({method: 'pause'})
            this.$videoPlayers.each((index, videoPlayer) => {
                if (videoPlayer.contentWindow) { // safe in case iframe is not ready
                    videoPlayer.contentWindow.postMessage(message, "*")
                }
            })
        }
    }
    handleResize(e) {
        BasePage.prototype.handleResize.apply(this, arguments)
        if (this.zoomOverlayClass) {
            this.zoomOverlayClass.handleResize(e)
        }
    }
    transitionIn() {
        // force resize in case previous page had a scrollbar
        if (this.carouselSwiper) {
           this.carouselSwiper.onResize()
        }
        if (PellMell.imageCoverHelper) {
           PellMell.imageCoverHelper.handleResize()
        }

        BasePage.prototype.transitionIn.apply(this, arguments)
    }
    beforeTransitionOut() {
        BasePage.prototype.beforeTransitionOut.apply(this, arguments)
        this.pauseAllVideos()
    }
}
