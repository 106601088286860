import _merge from 'lodash.merge'
import TweenLite from 'tweenlite'
import $ from 'jquery'

const basePageDefaultSettings = {
    transitionType: false,
    nodeType: '',
    nodeName: '',
    contextType: ''
}

export default class BasePage {
    constructor(options) {
        const settings = _merge(basePageDefaultSettings, options)
        const keysArr = Object.keys(basePageDefaultSettings)

        // Store settings (registered in defaults) on 'this' for quick access
        for(let i=0; i<keysArr.length; i++) {
            const key = keysArr[i]
            this[key] = settings[key]
        }

        // Store all other settings
        this.settings = settings

        // Dynamic settings
        this.id = `${this.nodeType}-${this.nodeName}`
        this.$el = $(`[data-pm-pageid="${this.id}"]`)
        this.isRemoved = false // Use to test is view was hidden/removed
        this.$animIntroSlideEls = this.findChildren('.anim-intro-slide')

        // Inits
        this.addEvents()
        this.handleOnLoad()
        PellMell.header.setColorReverse(false)
    }
    addEvents() {
        // this.pageLog('addEvents')
    }
    removeEvents() {
        // this.pageLog('removeEvents')
    }
    handleResize(e) {
        // this.pageLog('handleRezize')
    }
    handleOnLoad() {
        // this.pageLog('handleOnLoad')
        switch (this.contextType) {
            case 'static':
                this.handleResize()
                break
            case 'ajax':
                this.show()
                break
            default:
                break
        }
    }
    getPageTransitionColor() {
        let color = this.$el.attr('data-pm-pageTransitionColor')
        if (!color) {
           color = this.$el.css('backgroundColor')
        }
        return color
    }
    show() {
        // this.pageLog('show')

        if (!this.$el) {
            console.error('Ajax page container ($el) not found for transition')
            return false
        }

        if (PellMell.oldPage) {
            PellMell.oldPage.beforeTransitionOut()
        }
        this.beforeTransitionIn()

        PellMell.pageTransitioner.play({
            transitionType: this.transitionType,
            nodeType: this.nodeType,
            nodeName: this.nodeName,
            color: this.getPageTransitionColor(),
            onComplete: () => {
                if (PellMell.oldPage) {
                    PellMell.oldPage.transitionOut()
                }

                $('body')
                    .attr('id', this.id)
                    .attr('data-pm-nodetype', this.nodeType)
                    .attr('data-pm-nodename', this.nodeName)

                this.transitionIn()
            }
        })
    }
    beforeTransitionIn() {
        // this.pageLog('beforeTransitionIn')

        this.$el.css({
            zIndex: '2',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            opacity: 0
        })

        PellMell.resetHelpers() // Reset common helpers
    }
    transitionIn(){
        // this.pageLog('transitionIn')
        const tl = new TimelineLite({
            onComplete: this.transitionInEnd.bind(this)
        })
        const slidingElsArr = this.$animIntroSlideEls.toArray()
        const hasSlidingEls = this.$animIntroSlideEls.length ? true : false

        window.scrollTo(0, 0) // back to top

        tl.to(slidingElsArr, 0, {x: 0, y: '50px', z: 0, opacity: 0})
        tl.to(this.$el[0], hasSlidingEls ? 0.3 : 0.8, {opacity: 1})
        tl.staggerTo(slidingElsArr, 0.8, {x: 0, y: '0px', z: 0, opacity: 1}, 0.1)
    }
    transitionInEnd() {
        // this.pageLog('transitionInEnd')

        PellMell.pageTransitioner.hide()

        this.$el.css({
            zIndex: '',
            position: '',
            top: '',
            left: '',
            right: ''
        })
    }
    beforeTransitionOut() {
        // this.pageLog('beforeTransitionOut')

        this.isRemoved = true
        this.removeEvents()
        this.$el.css({
            zIndex: '0'
        })
    }
    transitionOut() {
        // this.pageLog('transitionOut')

        TweenLite.to(this.$el[0], 0, {opacity: 0, onComplete: this.transitionOutEnd.bind(this)})
    }
    transitionOutEnd() {
        // this.pageLog('transitionOutEnd')
        this.beforeDestroy()
        this.destroy()
    }
    beforeDestroy() {
        // this.pageLog('beforeDestroy')
    }
    destroy() {
        // this.pageLog('destroy')
        this.$el.remove()
    }
    pageLog(...messages) {
        if (PellMell.registry.devMode) {
            console.log(`[BasePage ID: ${this.id}]`, messages)
        }
    }
    findChildren(selector) {
        return this.$el.find(selector)
    }
    updatePageColors(isReversed) {
        this.$el[isReversed?'addClass':'removeClass']('is-colorreversed')
    }
}
