import "babel-polyfill"
import $ from 'jquery'
import select2 from 'select2'
import _merge from 'lodash.merge'
import _debounce from 'lodash.debounce'
import PellMellHistory from './tools/history'
import PellMellNav from './common/Nav'
import PellMellLoader from './common/Loader'
import PellMellHeader from './common/Header'
import PellMellPreloader from './common/Preloader'
import PageTransitioner from './common/Transitioner'
const {Modernizr} = window
import TweenLite from 'tweenlite'
import TweenLiteEasePack from 'tweenlite-easing-easepack'
import TweenLiteCssPlugin from 'tweenlite-plugin-css'
import TweenLiteScrollToPlugin from 'tweenlite-plugin-scrollto'
import ImageCover from './helpers/image-cover'

class PellMellApp {
    constructor() {
        this.firstResize = true
        this.registry = window.PellMellRegistry || {}
        this.ajaxEnabled = Modernizr.history
        this.oldPage = null
        this.currentPage = null
        this.$ajaxContainer = null
        this.isTouchDevice = Modernizr.touchevents
        this.lastMousePos = {pageX: 0, pageY: 0}
        this.preloader = null

        // GSAP
        TweenLite.defaultEase = Power3.easeOut

        this.viewport = {
            width: 1440,
            height: 900
        }
    }
    onDocumentReady() {
        if (this.registry.devMode) {
            $("#preloader").hide()
            this.onPreloadingComplete()
        } else {
            this.preloader = new PellMellPreloader($('img'), this.onPreloadingComplete.bind(this))
        }
    }
    onPreloadingComplete() {
        this.init()
        this.bindEvents()
    }
    init() {
        this.$ajaxContainer = $('#main-content')

        this.loader = new PellMellLoader()
        this.pageTransitioner = new PageTransitioner()
        this.history = new PellMellHistory()
        this.header = new PellMellHeader()
        this.nav = new PellMellNav()

        this.history.boot(this.registry.initialPage)

        this.resetHelpers()
    }
    bindEvents() {
        window.addEventListener('resize', _debounce(this.handleResize.bind(this), 50, false))
        window.addEventListener('orientationchange', _debounce(this.handleResize.bind(this), 50, false))
        this.handleResize()
    }
    handleResize(e) {
        const currentViewport = this.viewport
        const viewport = {
            width: window.innerWidth,
            height: window.innerHeight
        }
        if (currentViewport.width != viewport.width ||
                currentViewport.height != viewport.height ||
                this.firstResize )
        {
            if (this.firstResize) {
                this.firstResize = false
            }

            this.viewport = viewport

            this.nav.handleResize()

            if (this.currentPage &&
                    typeof this.currentPage.handleResize == 'function')
            {
                this.currentPage.handleResize(e)
            }
        }
    }
    resetHelpers() {
        if (!this.imageCoverHelper) {
            this.imageCoverHelper = new ImageCover('.resize-cover')
        } else {
            this.imageCoverHelper.reset()
        }
    }
}

window.PellMell = new PellMellApp()

$(document).ready(() => {
    // console.log('document is ready !')
    PellMell.onDocumentReady()
})
