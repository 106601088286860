import FeedPage from './FeedPage'
import $ from 'jquery'

export default class SearchPage extends FeedPage {
    constructor(options) {
        super(options)

        this.$tagsSelect = this.findChildren('.tags-select')
        this.$tagLinks = this.findChildren('.tag-link')
        this.$searchHomeLink = this.findChildren('#search-home')
        this.boundHandleTagNavClick = this.handleTagNavClick.bind(this)

        this.initTagsNav()
    }
    initTagsNav() {
        if (PellMell.ajaxEnabled) {
            this.$tagLinks.on('click', this.boundHandleTagNavClick)
        }
        this.$tagsSelect.select2({
            minimumResultsForSearch: Infinity, // hide search field
            templateResult: this.generateSelectOptionTemplate,
            templateSelection: this.generateSelectOptionTemplate
        })
        this.$tagsSelect.on('change', this.handleSelectChange.bind(this))
    }
    generateSelectOptionTemplate(state) {
        // console.log('generateSelectOptionTemplate', state)
        if (!state.id) { return state.text }
        const tagId = $(state.element).attr('data-pm-tagid')
        let tagIdArr = tagId.split('--')
        const tagCount = tagIdArr.pop()
        const tagName = tagIdArr.join(' ')
        return $(`<span class="tag-name u-font-semibold">${tagName}</span><span class="tag-count">${tagCount}</span>`)
    }
    handleSelectChange(e) {
        // console.log('handleSelectChange', e)
        const $select = $(e.currentTarget)
        const $selectOption = $select.find('option:selected')
        const tagId = $selectOption.val()
        const $selectedLink = tagId == 'all' ?
            this.$tagLinks.filter(`.is-active`) :
            this.$tagLinks.filter(`[data-pm-tagid="${tagId}"]`)

        if ($selectedLink) {
            $selectedLink.trigger('click')
        }
    }
    updateSelect(tagId) {
        // console.log('updateSelect', tagId)
        this.$tagsSelect.val(tagId)
        this.$tagsSelect.trigger('change.select2') // update select2
    }
    handleTagNavClick(e) {
        // console.log('handleTagNavClick', e)
        e.preventDefault()

        const $tagClicked = $(e.currentTarget)
        const tagId = $tagClicked.attr('data-pm-tagid')
        const isTagAcitve = $tagClicked.hasClass('is-active')
        const nextStateObj =
            PellMell.history.getStateObjectFromLink(isTagAcitve ?
                this.$searchHomeLink[0] :
                $tagClicked[0]
            )

        if (e.originalEvent) {
            this.updateSelect(isTagAcitve ? 'all' : tagId)
        }

        if (!isTagAcitve && tagId != 'all') {
            this.$tagLinks
                .removeClass('is-active')
                .addClass('is-unactive')

            $tagClicked
                    .removeClass('is-unactive')
                    .addClass('is-active')
        } else {
            this.$tagLinks.removeClass('is-active is-unactive')
        }

        this.toggleFeedVisibility(false)

        PellMell.history.manualReplaceState(nextStateObj)

        this.loadCategoryPage(nextStateObj.href)
    }
    loadCategoryPage(href) {
        // console.log('loadCategoryPage', href)

        $.ajax({
            url: href,
            type: 'get',
            success: this.handleCategoryPageLoaded.bind(this)
        })
    }
    handleCategoryPageLoaded(data) {
        // console.log('loadCategoryPage', data)

        // In case the page was removed during ajax request
        if (this.isRemoved) {
            return false
        }

        const $data = $(data)
        const $oldFeedItems = this.$feed.find('.feeditem')
        const $newFeedItems = $data.find('.feeditem')
        const $newFeedNavHtml = $data.find('.feednav').html()

        // Update feed nav
        this.$feedNav.html($newFeedNavHtml)
        this.resetLoadMoreBtn()
        // Update feed items
        this.feedMasonry.remove($oldFeedItems) // remove old items
        this.$feed.append($newFeedItems) // append new items
        this.feedMasonry.appended($newFeedItems) // notify masonry
        this.feedMasonry.layout() // force layout
        this.resetImageLoader()
    }
}
