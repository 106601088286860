import $ from 'jquery'

export default class Header {
    constructor() {
        this.$el = $('#main-header')
        this.$homeLink = $('#home-link')
        this.colorReverse = false

        this.addEvents()
    }
    addEvents() {
        if (this.$homeLink && PellMell.ajaxEnabled) {
            this.$homeLink.on('click', function (e) {
                const href = $(e.currentTarget).attr('href')
                if (!PellMell.nav.isOpen && href == window.location.pathname) {
                    window.location.reload()
                } else {
                    PellMell.history.handleLinkClick.apply(PellMell.history, arguments)
                }
            })
        }
    }
    getColorReverse() {
        return this.colorReverse
    }
    setColorReverse(isReverse) {
        if (this.colorReverse != isReverse) {
            this.colorReverse = isReverse
            this.updateUI()
        }
    }
    updateUI() {
        this.$el[this.colorReverse?'addClass':'removeClass']('is-colorreversed')
    }
}
