import $ from 'jquery'
import TweenLite from 'tweenlite'
import TimelineLite from 'timelinelite'
import imagesLoaded from 'imagesLoaded'

export default class Preloader {
    constructor($imgs, onComplete) {
        this.$el = $('#preloader')
        this.$bg = this.$el.find('.preloader-bg')
        this.$pageContainer = $('.page-container')
        this.$bgContent = this.$bg.children()
        this.$progressBar = this.$el.find('.preloader-progress')
        this.$progressBarWrapper = this.$progressBar.find('.preloader-layerwrapper')
        this.$progressContent = this.$progressBar.find('.preloader-logo, .preloader-baseline')
        this.$imgsToLoad = $imgs
        this.progressTween = null
        this.nbOfImagesLoaded = 0
        this.nbOfImagesTotal = $imgs.length
        this.loadingProgress = 0
        this.onComplete = onComplete && typeof onComplete == 'function' ? onComplete : (() => {})

        this.init()
        this.onResize()
        this.loadAllImages()
    }
    init() {
        let loaderColor = '#09687c'
        if (this.$pageContainer.length) {
            const transitionColor = this.$pageContainer.eq(0).attr('data-pm-pageTransitionColor')
            if (transitionColor) {
                loaderColor = transitionColor
            }
        }
        this.$el
            .find('.preloader-layer')
                .css('color', loaderColor)
                .filter('.preloader-layer--inversed')
                    .css('backgroundColor', loaderColor)
    }
    loadAllImages() {
        imagesLoaded(this.$imgsToLoad)
            .on('progress', this.handleLoadingProgress.bind(this))
            .on('always', this.handleLoadingComplete.bind(this))
    }
    handleLoadingProgress() {
        this.nbOfImagesLoaded = this.nbOfImagesLoaded + 1
        this.loadingProgress = Math.round(this.nbOfImagesLoaded / this.nbOfImagesTotal * 10000) / 100

        this.killProgressTween()
        TweenLite.to(this.$progressBar[0], 0.8, {width: `${this.loadingProgress}%`, ease: Linear.ease})
    }
    handleLoadingComplete() {
        const tl = new TimelineLite({
            onComplete: this.removeItself,
            onCompleteScope: this
        })

        this.killProgressTween()
        this.onComplete()

        tl.to(this.$progressBar[0], 0.3, {width: '100%'})
        tl.staggerTo(this.$progressContent.toArray().reverse(), 0.5, {opacity: 0}, 0.4)
        tl.to(this.$bgContent.toArray(), 0, {opacity: 0})
        tl.to(this.$progressBar[0], 1, {x: '101%'})
        tl.to(this.$bg[0], 1, {x: '101%'}, '-=0.8')
    }
    killProgressTween() {
        if (this.progressTween) {
            this.progressTween.kill()
            this.progressTween = null
        }
    }
    onResize() {
        // this.$progressBarWrapper.css('width', this.$el.width() + 'px')
    }
    removeItself() {
        this.$el.hide()
    }
}
