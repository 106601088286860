import BasePage from '../pages/BasePage'
import HomePage from '../pages/HomePage'
import ArtistPage from '../pages/ArtistPage'
import SearchPage from '../pages/SearchPage'
import ProjectPage from '../pages/ProjectPage'
import NewsletterPage from '../pages/NewsletterPage'
import ContactPage from '../pages/ContactPage'
import $ from 'jquery'
const {history} = window

export default class PellMellHistory {
    constructor() {
        this.isTransitioning = false

        this.init()
    }
    init() {
        if (PellMell.ajaxEnabled) {
            window.addEventListener('popstate', this.handleOnPopState.bind(this))
        }
    }
    handleOnPopState(e) {
        // console.log('PellMellHistory.handleOnPopState', e, e.state)
        if (typeof e.state !== 'undefined' && e.state !== null) {
            this.loadPage(e.state)
        }
    }
    handleLinkClick(e) {
        // console.log('handleLinkClick', e, e.currentTarget)
        e.preventDefault()

        const link = e.currentTarget
        const linkStateObj= this.getStateObjectFromLink(link)

        if (linkStateObj.nodeType &&
                linkStateObj.nodeName &&
                !this.isTransitioning &&
                link.className.indexOf('is-active') == -1 &&
                linkStateObj.href != window.location.pathname)
        {
            // this.isTransitioning = true

            history.pushState(linkStateObj, linkStateObj.title, linkStateObj.href)

            this.loadPage(linkStateObj)
        }
        else if (link.className.indexOf('is-active') > -1 ||
            linkStateObj.href == window.location.pathname) {
            PellMell.nav.closeMenu()
        }
    }
    getStateObjectFromLink(link) {
        return {
            transitionType: link.getAttribute('data-pm-transitiontype'),
            nodeType: link.getAttribute('data-pm-nodetype'),
            nodeName: link.getAttribute('data-pm-nodename'),
            title: link.getAttribute('title') || '',
            href: link.getAttribute('href')
        }
    }
    boot({transitionType, nodeType, nodeName, contextType}) {
        // console.log('PellMellHistory.boot', nodeType, nodeName, contextType)
        let page = null
        const pageOptions = {transitionType, nodeType, nodeName, contextType}

        switch(nodeType) {
            case 'page':
                switch(nodeName) {
                    case 'home':
                        page = new HomePage(pageOptions)
                        break
                    case 'categories':
                        page = new SearchPage(pageOptions)
                        break
                    case 'newsletter':
                        page = new NewsletterPage(pageOptions)
                        break
                    case 'contact':
                        page = new ContactPage(pageOptions)
                        break
                    default:
                        page = new BasePage(pageOptions)
                        break
                }
                break
            case 'artist':
                page = new ArtistPage(pageOptions)
                break
            case 'project':
            case 'projectitem':
                page = new ProjectPage(pageOptions)
                break
            default:
                page = new BasePage(pageOptions)
                break
        }

        PellMell.currentPage = page

        if (contextType == 'static' && PellMell.ajaxEnabled) {
            this.pushFirstState(pageOptions)
        }
    }
    pushFirstState(stateObj) {
        // console.log('PellMellHistory.pushFirstState', stateObj)
        history.pushState(stateObj, null, window.location.href)
    }
    manualReplaceState({nodeType, nodeName, href, title}) {
        const stateObj = {nodeType, nodeName, href, title}
        history.replaceState(stateObj, title, href)
    }
    loadPage(stateObj) {
        // console.log('PellMellHistory.loadPage', stateObj)
        const _self = this
        $.ajax({
            url: stateObj.href,
            type: 'get',
            success: function(data){
                PellMell.$ajaxContainer.append(data)

                PellMell.oldPage = PellMell.currentPage
                PellMell.currentPage = null

                _self.boot({
                    transitionType: stateObj.transitionType,
                    nodeType: stateObj.nodeType,
                    nodeName: stateObj.nodeName,
                    contextType: 'ajax'
                })

                PellMell.nav.updateUI(stateObj)

                // Update document title
                if (window.PellMellAjaxRegistry || window.PellMellAjaxRegistry.title) {
                    document.title = window.PellMellAjaxRegistry.title
                }

                // Analytics
                if(typeof ga !== 'undefined') {
                    ga('send', 'pageview', {'page': stateObj.href, 'title': stateObj.title})
                }
            }
        })
    }
}
