import $ from 'jquery'
import TweenLite from 'tweenlite'

export default class ProjectZoomOverlay {
    constructor($el) {
        this.isOpen = false
        this.$el = $el
        this.$closeBtn = $el.find('.project-zoom-overlay-close')
        this.$visualContainer = $el.find('.project-zoom-overlay-visual')

        this.reset()
    }
    reset() {
        this.$visualImg = null
        this.visualImgRect = null
        this.$visualContainer.html('')
    }
    addEvents() {
        $(document).on('mousemove.projectzoomoverlay', this.handleMouseMove.bind(this))
        this.$closeBtn.one('click.projectzoomoverlay', (e) => {
            e.preventDefault()
            this.hide()
        })
        this.$visualImg.on('load.projectzoomoverlay', (e) => {
            this.handleResize() // force resize
            this.handleMouseMove() // force mousemove
        })
    }
    removeEvents() {
        $(document).off('mousemove.projectzoomoverlay')
        this.$closeBtn.off('click.projectzoomoverlay')
        this.$visualImg.off('load.projectzoomoverlay')
    }
    handleResize(e = null) {
        if (this.isOpen && this.$visualImg.length) {

            // If image orientation is different from window orientation, scale only to 1 otherwise to 1.5
            const visualImgRect = this.$visualImg[0].getBoundingClientRect()
            const imgRatio = visualImgRect.width/visualImgRect.height
            const containerRatio = this.$visualContainer.width()/this.$visualContainer.height()
            const scaleTo = imgRatio < 1 && containerRatio > 1 || imgRatio > 1 && containerRatio < 1  ? 1 : 1.5
            TweenLite.to(this.$visualImg[0], 0, {scale: scaleTo})

            this.visualImgRect = this.$visualImg[0].getBoundingClientRect()
        }
    }
    handleMouseMove(e = {}) {
        e.pageX = e.pageX || PellMell.lastMousePos.pageX
        e.pageY = e.pageY || PellMell.lastMousePos.pageY

        if (PellMell.nav.isOpen || !e.pageX || !e.pageY) {
            return
        }

        this.closeBtnFollowCursor(e.pageX, e.pageY)
        this.visualPositionDependingOnCursor(e.pageX, e.pageY)
    }
    closeBtnFollowCursor(pageX, pageY) {
        const unactiveZoneHeight = 100 // unactive zone at bottom and top to access menus
        const isInActiveZone = pageY > unactiveZoneHeight && pageY < window.innerHeight - unactiveZoneHeight

        if ( isInActiveZone && this.$closeBtn.hasClass('is-disabled') ) {
            this.$closeBtn.removeClass('is-disabled')
        }
        else if ( !isInActiveZone && !this.$closeBtn.hasClass('is-disabled') ) {
            this.$closeBtn.addClass('is-disabled')
        }

        TweenLite.to(this.$closeBtn[0], 0, {left: pageX + 'px', top: pageY + 'px'})
    }
    visualPositionDependingOnCursor(pageX, pageY) {
        if (!this.visualImgRect || !this.$visualImg.length) {
            return false // stop here !
        }
        const {width: vW, height: vH} = PellMell.viewport
        const pageXPerc = pageX / vW * 100
        const pageYPerc = pageY / vH * 100
        const diffWidth = (vW - this.visualImgRect.width)
        const diffHeight = (vH - this.visualImgRect.height)
        const xTo = diffWidth < 0 ?
            (diffWidth / 100 * pageXPerc) - (diffWidth/2) :
            0
        const yTo = diffHeight < 0 ?
            (diffHeight / 100 * pageYPerc) - (diffHeight/2) :
            0

        TweenLite.to(this.$visualImg[0], 0.8, {x: xTo + 'px', y: yTo + 'px'})
    }
    show(imgSrc) {
        const $img = $('<img>').addClass('resize-cover', imgSrc).attr('src', imgSrc)

        this.isOpen = true
        TweenLite.to(this.$el[0], 0, {opacity: 0})
        this.$el.show()
        this.$visualContainer.html($img)
        PellMell.imageCoverHelper.addImage($img[0])
        this.$visualImg = $img

        this.addEvents()
        this.handleResize() // force resize
        this.handleMouseMove() // force mousemove
        TweenLite.to(this.$el[0], 0, {opacity: 1})
    }
    hide() {
        this.isOpen = false
        this.removeEvents()
        this.reset()
        this.$el.hide()
    }
    destroy() {
        this.hide()
    }
}
