import $ from 'jquery'
import TweenLite from 'tweenlite'
import TimelineLite from 'timelinelite'

const noopFn = () => {}

export default class PellMellLoader {
    constructor() {
        this.$el = $('#page-transitioner')
        this.$layers = this.$el.find('.page-transitioner-layer')
    }
    play({transitionType = false, nodeType = false, nodeName = false, color, onComplete}) {
        if (!transitionType || transitionType == '') {
            transitionType = this.getPossibleTransitionType(nodeType, nodeName)
        }
        if (transitionType && typeof this[transitionType] == 'function') {
            this[transitionType]({color, onComplete})
        } else {
            this.slideLeft({color, onComplete})
        }
    }
    getPossibleTransitionType(nodeType, nodeName) {
        let transitionType = false
        switch (nodeType) {
            case 'artist':
                transitionType = 'slideTop'
                break
            case 'page':
                switch (nodeName) {
                    case 'newsletter':
                    case 'contact':
                        transitionType = 'slideBottom'
                        break
                    case 'categories':
                        transitionType = 'slideTop'
                        break
                    default:
                        break
                }
                break
            default:
                break
        }
        return transitionType
    }
    slideLeft({color, onComplete, isSingle = false}) {
        this.slide({
            fromObj: {x: '100%', y: 0, z: 0},
            toObj: {x: '0%', y: 0, z: 0},
            color,
            onComplete,
            isSingle
        })
    }
    slideRight({color, onComplete, isSingle = false}) {
        this.slide({
            fromObj: {x: '-100%', y: 0, z: 0},
            toObj: {x: '0%', y: 0, z: 0},
            color,
            onComplete,
            isSingle
        })
    }
    slideTop({color, onComplete, isSingle = false}) {
        this.slide({
            fromObj: {x: 0, y: '100%', z: 0},
            toObj: {x: 0, y: '0%', z: 0},
            color,
            onComplete,
            isSingle
        })
    }
    slideBottom({color, onComplete, isSingle = false}) {
        this.slide({
            fromObj: {x: 0, y: '-100%', z: 0},
            toObj: {x: 0, y: '0%', z: 0},
            color,
            onComplete,
            isSingle
        })
    }
    slideSingleRight({color, onComplete}) {
        this.slideRight({color, onComplete, isSingle: true})
    }
    slideSingleLeft({color, onComplete}) {
        this.slideLeft({color, onComplete, isSingle: true})
    }
    slide({fromObj = {}, toObj = {}, color = 'transparent', isSingle = false, onComplete = noopFn}) {
        const tl = new TimelineLite({
            onComplete: onComplete
        })
        const layersArr = this.$layers.toArray()

        if (PellMell.nav.isOpen) {
            PellMell.nav.closeMenu()
            toObj['delay'] = 0.5
        }

        TweenLite.to(layersArr, 0, fromObj)
        TweenLite.to(layersArr.slice(-1), 0, {backgroundColor: color})
        this.$el.show()
        tl.staggerTo(layersArr, isSingle ? 0.5 : 0.8, toObj, isSingle ? 0 : 0.3)
    }
    hide() {
        this.$el.hide()
    }
}
