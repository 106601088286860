import BasePage from './BasePage'
import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesLoaded'
import $ from 'jquery'
import TweenLite from 'tweenlite'
import TimelineLite from 'timelinelite'

export default class FeedPage extends BasePage {
    constructor(options) {
        super(options)

        this.$feed = this.findChildren('.feed')
        this.$feedNav = this.findChildren('.feednav')
        this.$loadMoreBtn = null
        this.feedMasonry = null
        this.imageLoader = null
        this.feedItemsTimeline = new TimelineLite()
        this.boundHandleLoadMoreClick = this.handleLoadMoreClick.bind(this)

        this.initLoadMoreBtn()
        this.initMasonry()
        this.resetProjectLinks()
    }
    initMasonry() {
        if (this.$feed) {
            this.feedMasonry = new Masonry(this.$feed[0], {
                itemSelector: '.feeditem',
                gutter: 40,
                transitionDuration: 0
            })
            this.resetImageLoader()
        }
    }
    resetProjectLinks() {
        const $feedItems = this.$feed.find('.feeditem > a')
        $feedItems.off('click.feedpage')
        $feedItems.on('click.feedpage', PellMell.history.handleLinkClick.bind(PellMell.history))
    }
    resetImageLoader($feedItems, callback) {
        $feedItems = $feedItems || this.$feed.find('.feeditem')
        const feedItemsTumbsArr = $feedItems.find('.feeditem-thumbnailwrapper').toArray()
        const feedItemsArr = $feedItems.toArray()
        const staggerItemsArr = feedItemsArr.concat(feedItemsTumbsArr)
        const $feedItemsImages = $feedItems.find('img')
        const imagesLoadedComplete = () => {
            this.feedMasonry.layout()
            this.toggleFeedVisibility(true, true)
            this.resetProjectLinks()
            this.feedItemsTimeline.staggerTo(staggerItemsArr, 0.8, {y: '0%', opacity: 1}, 0.1)
            if (this.$loadMoreBtn.length) {
                this.startWatchScroll()
            }
            if (callback && typeof callback == 'function') {
                callback()
            }
        }
        let nbImagesComplete = 0
        $feedItemsImages.each((i, img) => {
            if (img.complete) {
                nbImagesComplete = nbImagesComplete + 1
            }
        })
        // Animate grid only if new images (not all loaded previously)
        if (nbImagesComplete >= $feedItemsImages.length) {
            imagesLoadedComplete()
        }
        else {
            TweenLite.to(staggerItemsArr, 0, {y: '100%', opacity: 0})
            imagesLoaded(this.$feed).on('always', imagesLoadedComplete)
        }
        // following lines added to force masonry layout each time an image is loaded to prevent overlaps in the grid (safari mostly)
        this.feedMasonry.layout()
        $feedItemsImages.off('load.feedpage')
        $feedItemsImages.on('load.feedpage', () => {
            if (this.feedMasonry) {
                this.feedMasonry.layout()
            }
        })
    }
    initLoadMoreBtn() {
        this.$loadMoreBtn = this.findChildren('.feednav-next')
        this.$loadMoreBtn.on('click.loadmore', this.boundHandleLoadMoreClick)
    }
    resetLoadMoreBtn() {
        if (this.$loadMoreBtn) {
            this.$loadMoreBtn.off('click.loadmore')
            this.$loadMoreBtn.remove()
            this.$loadMoreBtn = null
        }
        this.initLoadMoreBtn()
    }
    updateLoadMoreBtn($el) {
        if ($el && $el.length) {
            this.$loadMoreBtn
                    .attr('href', $el.attr('href'))
                    .attr('title', $el.attr('title'))
        } else {
            this.$loadMoreBtn
                    .off('click.loadmore')
                    .remove()
        }
    }
    handleLoadMoreClick(e) {
        // console.log('handleLoadMoreClick', e)
        e.preventDefault()
        const $link = $(e.currentTarget)
        const linkHref = $link.attr('href')
        this.stopWatchScroll()
        this.loadNextPage(linkHref)
    }
    loadNextPage(href) {
        // console.log('loadNextPage', href)
        this.toggleFeedLoader(true)
        $.ajax({
            url: href,
            type: 'get',
            success: this.handleNextPageLoaded.bind(this)
        })
    }
    handleNextPageLoaded(data) {
        // console.log('handleNextPageLoaded')
        // In case the page was removed during ajax request
        if (this.isRemoved) {
            return false
        }

        const $data = $(data)
        const $newFeedItems = $data.find('.feeditem')
        const $newLoadMoreBtn = $data.find('.feednav-next')

        // update nav
        this.updateLoadMoreBtn($newLoadMoreBtn)

        // Update Feed
        this.$feed.append($newFeedItems) // append items

        // Project links
        this.resetProjectLinks()

        // Update Masonry and wait for load
        this.feedMasonry.appended($newFeedItems) // notify masonry
        this.feedMasonry.layout() // force layout
        this.resetImageLoader($newFeedItems, () => {
            this.toggleFeedLoader(false)
            // Scroll down
            TweenLite.to(window, 0.8, {scrollTo: '+=200', delay: 0.1})
        })
    }
    toggleFeedLoader(show = false) {
        this.$loadMoreBtn[show?'addClass':'removeClass']('is-loading')
    }
    toggleFeedVisibility(show = false, preventAnim = false) {
        if (!show) {
            this.stopWatchScroll()
        }
        TweenLite.to(this.$feed, preventAnim ? 0 : 0.3, {opacity: show ? 1 : 0})
    }
    startWatchScroll() {
        $(window)
            .off('scroll.feedpage')
            .on('scroll.feedpage', this.handleOnScroll.bind(this))
    }
    stopWatchScroll() {
        $(window).off('scroll.feedpage')
    }
    handleOnScroll(e) {
        const windowHeight = window.innerHeight
        const scrollTop = $(window).scrollTop()
        const loadMorePosition = this.$loadMoreBtn.offset().top
        const loadMoreHeight = this.$loadMoreBtn.outerHeight()
        const isInViewport = loadMorePosition < (windowHeight + scrollTop - loadMoreHeight)
        if (isInViewport) {
            this.stopWatchScroll()
            this.$loadMoreBtn.trigger('click')
        }
    }
}
